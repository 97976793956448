import "./App.css";
import { Route, Routes } from "react-router-dom";
import { Login } from "./Components/Login/Login";
import { InscripcionCliente } from "./Components/Inscripcion-Cliente/InscripcionCliente";
import Eventos from "./Components/Eventos/Eventos";
import { ViewInscripcion } from "./Components/Inscripcion-Cliente/View-Inscripcion/ViewInscripcion";
import { ViewInscripcionCliente } from "./Components/Inscripcion-Cliente/View-Inscripcion/ViewInscripcionCliente";
import RequireAuth from "./Components/Validation/RequireAuth";
import Layout from "./Components/Layout/Layout";
import Users from "./Components/Users/Users";
import Init from "./Components/Init/Init";
import Unauthorized from "./Components/Unauthorized/unauthorized";
import PersistLogin from "./Components/Persist/PersistLogin";
import InscripcionManual from "./Components/Asesor/Inscripcion/Manual/Inscripcion-Manual";
import InscripcionMasiva from "./Components/Asesor/Inscripcion/Masiva/Inscripcion-Masiva";
import Politicas from "./Components/Politicas/Politicas";
import Cartera from "./Components/Asesor/Cartera/Cartera";
import Asesores from "./Components/Asesores/Asesores";
import Formulario from "./Components/Eventos/Evento-Detalle/Dashboard/Formularios/Formulario";
import Actualizar from "./Components/Login/Actualizacion/Actualizar";
import RespuestasFormulario from "./Components/Eventos/Evento-Detalle/Dashboard/Formularios/RespuestasFormulario";
import Carpeta from "./Components/Carpeta/Carpeta";
import FormularioParam from "./Components/Eventos/Evento-Detalle/Dashboard/Formularios/FormularioParam";
import { SeguimientoInscripcion } from "./Components/Inscripcion-Cliente/View-Inscripcion/SeguimientoInscripcion";
import Recuperar from "./Components/Login/Actualizacion/Recuperar";
import Landing from "./Components/Eventos/Evento-Detalle/Page/Landing";
import Page from "./Components/Asesores/Page";
import InscripcionAsesor from "./Components/Inscripcion-Cliente/InscripcionAsesor/InscripcionAsesor";
import PageForos from "./Components/Asesores/PageForos";
import Participante from "./Components/Eventos/Evento-Detalle/Participante/Participante";
import Admin from "./Components/Home/Admin";
import InscripcionEvento from "./Components/Inscripcion-Cliente/InscripcionEvento/InscripcionEvento";
import InscripcionCupon from "./Components/Inscripcion-Cliente/InscripcionCupon/InscripcionCupon";
import Subgerencia from "./Components/SubGerencia/Subgerencia";
import DiplomasYCertificados from "./Components/Eventos/Evento-Detalle/Dashboard/Diplomas/DiplomasYCertificados";
import Promocion from "./Components/Eventos/Evento-Detalle/Page/Promocion";
import FormCliente from "./Components/in-house/FormCliente";
import ViewInHouse from "./Components/in-house/ViewInHouse";
import Mail from "./Components/mails-masivos/Mail";
import NuevoMail from "./Components/mails-masivos/NuevoMail";
import VerMails from "./Components/mails-masivos/VerMails";
import Pagos from "./Components/Pagos/Pagos";
import CreatePago from "./Components/Pagos/CreatePago";
import UpdatePago from "./Components/Pagos/UpdatePago";
import ViewPago from "./Components/Pagos/ViewPago";
import AllPagos from "./Components/Pagos/AllPagos";
import PagosNoVinculados from "./Components/Pagos/PagosNoVinculados";
import ControlTiempo from "./Components/Eventos/Evento-Detalle/Dashboard/Jornada/control-tiempo/ControlTiempo";
import Recepcion from "./Components/Eventos/Evento-Detalle/Dashboard/Recepcion/Recepcion";
import CRM from "./Components/CRM/crm";
import Dashboard from "./Components/CRM/dashboard";
import NuevoLead from "./Components/CRM/NuevoLead";
import Seguimiento from "./Components/CRM/Seguimiento";
import View from "./Components/CRM/View";
import Ventas from "./Components/CRM/Ventas";
import EditLead from "./Components/CRM/view-lead/EditLead";
import Interesados from "./Components/CRM/Interesados";
import GafetesFisicos from "./Components/Eventos/Evento-Detalle/Dashboard/Gafetes-Fisicos/GafetesFisicos";
import CreateGafeteFisico from "./Components/Eventos/Evento-Detalle/Dashboard/Gafetes-Fisicos/CreateGafeteFisico";
import ViewGafetesFisicos from "./Components/Eventos/Evento-Detalle/Dashboard/Gafetes-Fisicos/ViewGafetesFisicos";
import ReadGafetesFisicos from "./Components/Eventos/Evento-Detalle/Dashboard/Gafetes-Fisicos/ReadGafetesFisicos";
import ManejoParticipantes from "./Components/Eventos/Evento-Detalle/Dashboard/Manejo-Participantes/ManejoParticipantes";
import ParticipantesHeader from "./Components/Eventos/Evento-Detalle/Dashboard/Manejo-Participantes/ParticipantesHeader";
import EditParticipante from "./Components/Eventos/Evento-Detalle/Dashboard/Manejo-Participantes/EditParticipante";
import Tarifas from "./Components/Eventos/Evento-Detalle/Dashboard/Tarifas/Tarifas";
import CrearTarifa from "./Components/Eventos/Evento-Detalle/Dashboard/Tarifas/CrearTarifa";
import DetalleTarifa from "./Components/Eventos/Evento-Detalle/Dashboard/Tarifas/DetalleTarifa";
import AllTarifas from "./Components/Eventos/Evento-Detalle/Dashboard/Tarifas/AllTarifas";
import Variables from "./Components/Variables/Variables";
import InHouse from "./Components/in-house/InHouse";
import FormInHouse from "./Components/in-house/FormInHouse";
import FormulariosLayout from "./Components/Formularios/Formularios";
import DashboardFormularios from "./Components/Formularios/Dashboard";
import OkInHouse from "./Components/in-house/OkInHouse";
import MenuLayout from "./Components/Layout/MenuLayout";
import ViewPropuesta from "./Components/in-house/ViewPropuesta";
import CheckAttendance from "./Components/Eventos/Evento-Detalle/Dashboard/Manejo-Participantes/CheckAttendance";
function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route
            path="/mi-gafete-electronico/:hash"
            element={<CheckAttendance />}
          ></Route>

          <Route path="login" element={<Login />}></Route>
          <Route path="/" element={<Init />}></Route>
          <Route path="/unauthorized" element={<Unauthorized />}></Route>
          <Route
            path="inscripcion/ver/:id"
            element={<ViewInscripcion />}
          ></Route>
          <Route
            path="inscripcion/public/ver/:id"
            element={<ViewInscripcionCliente />}
          ></Route>
          <Route path="politicas" element={<Politicas />}></Route>
          <Route path="/asesores/bclaudio" element={<Asesores />}></Route>
          <Route
            path="/formularios/:correlativo"
            element={<Formulario />}
          ></Route>
          <Route
            path="/formularios/:correlativo/:param"
            element={<FormularioParam />}
          ></Route>
          <Route path="/carpetas/:correlativo" element={<Carpeta />}></Route>
          <Route
            path="/recovery/:token_recovery"
            element={<Recuperar />}
          ></Route>
          <Route path="/evento/:hash" element={<Landing />}></Route>
          <Route path="/evento/promocion/:hash" element={<Promocion />}></Route>
          <Route path="/asesor/page/:hash" element={<Page />}></Route>
          <Route
            path="/asesor/page/foros/:hash"
            element={<PageForos />}
          ></Route>
          <Route
            path="/inscripciones/:evento/:asesor/:cupon"
            element={<InscripcionCupon />}
          ></Route>
          <Route
            path="/inscripciones/:evento/:asesor"
            element={<InscripcionAsesor />}
          ></Route>
          <Route
            path="/inscripciones/:evento"
            element={<InscripcionEvento />}
          ></Route>
          <Route
            path="/diplomas-y-certificados/:participante"
            element={<DiplomasYCertificados />}
          ></Route>
          <Route path="/badge/:hash" element={<Participante />}></Route>
          <Route path="/in-house/solicitud" element={<FormCliente />}></Route>
          <Route path="/in-house/outlet" element={<OkInHouse />}></Route>
          <Route
            path="/in-house/solicitud/:hash"
            element={<ViewInHouse />}
          ></Route>
          <Route element={<PersistLogin />}>
            <Route element={<RequireAuth allowedRoles={[1, 4, 6]} />}>
              <Route
                path="inscripcion"
                element={<InscripcionCliente />}
              ></Route>
              <Route
                path="asesor/inscripcion/manual"
                element={<InscripcionManual />}
              ></Route>
              <Route
                path="asesor/inscripcion/masiva"
                element={<InscripcionMasiva />}
              ></Route>
              <Route path="asesor/cartera" element={<Cartera />}></Route>
              <Route path="asesor" element={<Admin />}></Route>
              <Route path="crm" element={<CRM />}>
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="nuevo-lead" element={<NuevoLead />} />
                <Route path="seguimiento" element={<Seguimiento />} />
                <Route path="seguimiento/:evento" element={<Interesados />} />
                <Route path="ventas" element={<Ventas />} />
                <Route path="seguimiento/view/:id" element={<View />} />
                <Route path="seguimiento/edit/:id" element={<EditLead />} />
              </Route>
            </Route>
            <Route element={<RequireAuth allowedRoles={[1, 2, 3, 4]} />}>
              <Route path="control-academico" element={<Admin />}></Route>
              <Route path="tarifas/:hash" element={<Tarifas />}>
                <Route path="create" element={<CrearTarifa />}></Route>
                <Route path=":id" element={<DetalleTarifa />}></Route>
                <Route path="all" element={<AllTarifas />}></Route>
              </Route>
              <Route element={<MenuLayout />}>
                <Route
                  path="/in-house/seguimiento"
                  element={<InHouse />}
                ></Route>
                <Route
                  path="/in-house/seguimiento/:UniqueID"
                  element={<ViewPropuesta />}
                ></Route>
                <Route
                  path="/in-house/registrar"
                  element={<FormInHouse />}
                ></Route>
              </Route>
              <Route path="formularios" element={<FormulariosLayout />}>
                <Route
                  path="dashboard"
                  element={<DashboardFormularios />}
                ></Route>
              </Route>
            </Route>
            <Route
              element={<RequireAuth allowedRoles={[1, 2, 3, 4, 6, 7, 8]} />}
            >
              <Route path="participantes" element={<ParticipantesHeader />}>
                <Route path=":hash" element={<ManejoParticipantes />}></Route>
                <Route path="edit/:hash" element={<EditParticipante />} />
              </Route>
              <Route path="gafetes-fisicos/:hash" element={<GafetesFisicos />}>
                <Route path="create" element={<CreateGafeteFisico />} />
                <Route path="view/:UniqueID" element={<ViewGafetesFisicos />} />
                <Route path="all" element={<ReadGafetesFisicos />} />
              </Route>
            </Route>
            <Route element={<RequireAuth allowedRoles={[3, 4]} />}>
              <Route
                path="home/direccion-academica"
                element={<Admin />}
              ></Route>
            </Route>
            <Route element={<RequireAuth allowedRoles={[4]} />}>
              <Route path="home" element={<Admin />}></Route>
              <Route path="home/admin" element={<Admin />}></Route>
              <Route path="eventos" element={<Eventos />}></Route>
              <Route path="test" element={<Users />}></Route>
              <Route path="variables" element={<Variables />}></Route>
              <Route
                path="control-tiempo/:UniqueID"
                element={<ControlTiempo />}
              ></Route>
            </Route>
            <Route element={<RequireAuth allowedRoles={[4, 2, 1]} />}>
              <Route path="mails-masivos" element={<Mail />}>
                <Route path="programar-envio" element={<NuevoMail />} />
                <Route path="envios-programados" element={<VerMails />} />
              </Route>
            </Route>
            <Route element={<RequireAuth allowedRoles={[4, 6, 1]} />}>
              <Route path="pagos-electronicos" element={<Pagos />}>
                <Route path="view/:id" element={<ViewPago />} />
                <Route path="all" element={<AllPagos />} />
                <Route element={<RequireAuth allowedRoles={[4, 6]} />}>
                  <Route path="no-vinculado" element={<PagosNoVinculados />} />
                  <Route path="create" element={<CreatePago />} />
                  <Route path="update/:id" element={<UpdatePago />} />
                </Route>
              </Route>
            </Route>
            <Route element={<RequireAuth allowedRoles={[4, 6, 8]} />}>
              <Route path="contabilidad" element={<Admin />}></Route>
            </Route>
            <Route element={<RequireAuth allowedRoles={[1, 2, 4, 5, 6]} />}>
              <Route
                path="/formularios/respuestas/:correlativo"
                element={<RespuestasFormulario />}
              ></Route>
            </Route>
            <Route
              element={<RequireAuth allowedRoles={[1, 2, 3, 4, 6, 7, 8]} />}
            >
              <Route
                path="/usuarios/actualizar"
                element={<Actualizar />}
              ></Route>
              <Route
                path="pagina-recepcion/:hash"
                element={<Recepcion />}
              ></Route>
            </Route>
            <Route element={<RequireAuth allowedRoles={[2, 4, 8, 9]} />}>
              <Route
                path="/home/sub-gerencia-ebg"
                element={<Subgerencia />}
              ></Route>
            </Route>
            <Route element={<RequireAuth allowedRoles={[7, 4]} />}>
              <Route path="/Diseno" element={<Admin />}></Route>
            </Route>
            <Route element={<RequireAuth allowedRoles={[4, 6]} />}>
              <Route
                path="inscripcion/seguimiento/:id"
                element={<SeguimientoInscripcion />}
              ></Route>
            </Route>
          </Route>
        </Route>
      </Routes>
    </div>
  );
}
export default App;
